import * as React from 'react';
import { Layout, Header, PageTitle, PageSubTitle, Main, Footer } from '../components/Layout';
import { Container } from '../components/Container';
import { Section } from '../components/Section';

const Page404 = () => {
  return (
    <Layout>
      <Header />

      <Main>
        <Section css={{ paddingBottom: '24px' }}>
          <Container
            css={{
              padding: '12px',
            }}
          >
            <div css={{ fontSize: '6px' }}>
              <PageTitle>404 Pagina niet gevonden</PageTitle>
              <PageSubTitle>Het lijkt erop dat de pagina niet kon worden gevonden.</PageSubTitle>
            </div>
          </Container>
        </Section>
      </Main>

      <Footer />
    </Layout>
  );
};

export default Page404;
